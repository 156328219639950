import React from "react";
import { Routes, Route } from "react-router-dom";
import EmptyLayout from "./layouts/EmptyLayout";
import NotFoundPage from "./pages/NotFoundPage.tsx";
import MainPage from "./pages/MainPage";
import MaterplanPage from "./pages/dev/MaterplanPage";
import SpaceModePage from "./pages/dev/SpaceModePage";
import TimelineModePage from "./pages/dev/TimelineModePage";
import StudiosPage from "./pages/dev/StudiosPage";
import ExpoPage from "./pages/\bexpoPage";

export default function App() {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Routes>
        {/* 프러덕션 라우트 */}
        <Route element={<EmptyLayout />} path="/">
          <Route index element={<MainPage />} path="" />
          <Route index element={<ExpoPage />} path="expo" />
        </Route>
        {/* 개발용 라우트 */}
        <Route element={<EmptyLayout />} path="/dev">
          <Route element={<StudiosPage />} path="studios" /> 
          <Route element={<MaterplanPage />} path="masterplan" />
          <Route element={<SpaceModePage />} path="space-mode" />
          <Route element={<TimelineModePage />} path="timeline-mode" />
        </Route>
        {/* Notfound page */}
        <Route element={<EmptyLayout />} path="/">
          <Route element={<NotFoundPage />} path="*" />
        </Route>
      </Routes>
    </div>
  );
}
