import { TPhase } from "../..";
import modelings from "../../modelings.data";

export type TItem = {
  name: string;
  desc: string;
  date: string;
  phase: TPhase;
  modelPath: string;
  phases: {
    name: string;
    desc: string;
    phase: TPhase;
  }[];
};

export type TSpaceModeVm = {
  phase: TPhase;
  items: TItem[];
};

const dataBaseUrl = process.env.REACT_APP_DATA_BASE_URL || "";

export const landingVm: TSpaceModeVm = {
  phase: {
    position: [ -3051457.7220468684, 4042704.247646128, 3864409.8293511905],
    orientation: {
        heading: 0.313156875998288,
        pitch: -0.27180005163537535,
        roll: 0.000030882813662813646
    },
  },
  items: [
    {
      name: "성수클러스터_2차",
      desc: "성수클러스터",
      date: "2024.04",
      modelPath: `${dataBaseUrl}/models/3d-tiles-merged/2024/tileset.json`,
      phase: {
        position: [-3052400.056859965, 4043103.349480272, 3867396.740176105],
        orientation: {
heading: 0.313156875998288,
pitch: -0.27180005163537535,
roll: 0.000030882813662813646
        },
      },
      phases: modelings.map((model) => {
        return {
          name: model.name,
          desc: model.desc,
          phase: model.phase,
        };
      }),
    },
  
  ],
};
