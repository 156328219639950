import { TPhase } from "../..";
import modelings from "../../modelings.data";

export type TItem = {
  name: string;
  desc: string;
  date: string;
  phase: TPhase;
  modelPath: string;
  phases: {
    name: string;
    desc: string;
    phase: TPhase;
  }[];
  data?: {
    startDt: string;
    endDt: string;
    dtType: string;
    type: string;
    place: string;
    detail: string;
    pbDate: string;
    fileType: string;
    volume: string;
    count: string;
    link: string;
    url: string;
  };
};

export type TSpaceModeVm = {
  phase: TPhase;
  items: TItem[];
};

const dataBaseUrl = process.env.REACT_APP_DATA_BASE_URL || "";

export const spaceModeVm: TSpaceModeVm = {
  phase: {
    position: [-3051457.7220468684, 4042704.247646128, 3864409.8293511905],
    orientation: {
      heading: 0.313156875998288,
      pitch: -0.27180005163537535,
      roll: 0.000030882813662813646,
    },
  },
  items: [
    {
      name: "반포 주공아파트 1차",
      desc: "반포주공아파트 1차",
      date: "2022.10",
      modelPath: `${dataBaseUrl}/models/Banpo/2022/tileset.json`,
      phase: {
        position: [-3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      },
      phases: modelings
        .filter((m) => m.name !== "C06")
        .map((model) => {
          return {
            name: model.name,
            desc: model.desc,
            phase: model.phase,
          };
        }),

    },
    {
      name: "반포 주공아파트 2차",
      desc: "반포주공아파트 2차",
      date: "2024.09",
      modelPath: `${dataBaseUrl}/models/Banpo/2024/tileset.json`,
      phase: {
        position: [ -3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      },
      phases: modelings.map((model) => {
        return {
          name: model.name,
          desc: model.desc,
          phase: model.phase,
        };
      }),
    },  
    {
      name: "반포 주공아파트 메뉴얼",
      desc: "반포주공아파트 메뉴얼",
      date: "2020.10",
      modelPath: `${dataBaseUrl}/models/Banpo/2024/tileset.json`,
      phase: {
        position: [ -3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      },
      phases: [
        {
          name: "HQ_1차",
          desc: "반포주공",
          phase: {
            position: [ -3048446.537082407, 4048246.620852872, 3861223.9001776567],
            orientation: {
              heading: 0.4212647721021163,
              pitch: -0.45391547585525416,
              roll: 0.000012186466604724444
            },
          },
        },
      ]
    },

    {
        name: "Bucheon Factory134 1차",
        desc: "부천팩토리134 1차",
        date: "2022.08",
        modelPath: `${dataBaseUrl}/models/Bucheon_factory134/tileset.json`,
        phase: {
          position: [ 
            -3036316.002025956, 4057758.650815029, 3859928.0185580365],
          orientation: {
            heading: 4.525555875824011, 
            pitch: -0.3904665155620266,
            roll: 6.283182763790971
          },
        },
        phases: [
          {
            name: "HQ_1차",
            desc: "부천팩토리134",
            phase: {
              position: [
                -3036316.002025956, 4057758.650815029, 3859928.0185580365,
              ],
              orientation: {
                heading: 4.525555875824011, 
                pitch: -0.3904665155620266,
                roll: 6.283182763790971
              },
            },
          },
        ],
      },

      {
        name: "Paju Saemal Village 1차",
        desc: "파주새말지구1차",
        date: "2023.10",
        modelPath: `${dataBaseUrl}/models/Paju_Guemchon/tileset.json`,
        phase: {
          position: [
            -3022257.148672632, 4043913.9089256343, 3884950.5842272164],
          orientation: {
            heading: 6.2001567464042235,
            pitch: -0.2298476001155474,
            roll: 0.000001778302731736403
          },
        },
        phases: [
          {
            name: "HQ_1차",
            desc: "파주",
            phase: {
              position: [
                -3022257.148672632, 4043913.9089256343, 3884950.5842272164
              ],
              orientation: {
                heading: 6.2001567464042235,
                pitch: -0.2298476001155474,
                roll: 0.000001778302731736403
              },
            },
          },
        ],
      },
    
  ],
};
